<template>
  <div class="docking-config">
    <div class="list-content">
      <el-form class="medium-form" label-width="85px" size="medium">
        <div class="config-section-title">新增企业提醒设置</div>
        <el-form-item label="企业置顶：">
          <el-switch class="mLeft" v-model="isTop" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
        <template v-if="isTop">
          <el-form-item label="置顶位置：">
            <el-checkbox-group class="mLeft" v-model="positionList">
              <el-checkbox v-for="item in topPositionArr" :label="item.value" :key="item.value">{{item.name}}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="置顶天数：">
            <el-input-number class="mLeft" v-model="num" :min="1" :max="10" label="描述文字"></el-input-number>
            <p class="info mTop"><i class="icon el-icon-info" />新增的企业将在企业库列表置顶展示</p>
          </el-form-item>
        </template>
      </el-form>
      <el-form class="medium-form" label-width="115px" size="medium">
        <div class="config-section-title">企业申请设置</div>
        <el-form-item label="企业申请设置：">
          <el-button type="primary" size="small" @click="jumpToCompanyForm">配置企业申请信息</el-button>
          <div style="padding-left: 20px; position: relative;margin-top: 20px; font-size: 14px;">
            <i class="el-icon-info" style="position: absolute; left: 0px;line-height: 1.5;color: #999;" />
            <p class="info" style="line-height: 1.5; margin-top: 0; font-size: 14px;">
              1.当申请入会设置支持单位入会后，会员单位入会填写的表单即是企业申请信息的表单<br/>
              2.当会员单位成功入会后，企业库亦会同时创建此单位信息<br/>
              3.会员创建企业信息填写的表单是不需要填写会内职务信息的
            </p>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <!-- 保存 -->
    <FixedActionBar>
      <el-button type="primary" @click="save">保存</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import FixedActionBar from "@/base/layout/FixedActionBar";
import { companyTopConfig, setCompanyTopConfig } from '../../../api/company/company-config'
export default {
  components: { FixedActionBar },
  data() {
    return {
      isTop: 0,
      positionList: [1],
      topPositionArr: [
        { value: 1, name: "全部分类页" },
        { value: 2, name: "所属分类页" },
      ],
      num: 3
    };
  },
  created() {
    this.getTopConfig()
  },
  methods: {
    // 跳转：配置企业申请信息
    jumpToCompanyForm() {
      let routerData = this.$router.resolve({name: "CycCompanyFormEditor"});
      window.open(routerData.href, "_blank");
    },
    getTopConfig() {
      companyTopConfig()
        .then(res => {
          const { data } = res
          this.isTop = data.status
          this.positionList = data.position
          this.num = data.days
        })
        .catch(err => console.log(err))
    },
    save() {
      const config = {
        status: this.isTop,
        position: this.positionList,
        days: this.num,
      }
      setCompanyTopConfig({config: config})
        .then((res) => {
          this.$message.success(res.msg)
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
};
</script>

<style lang="scss" scoped>
.docking-config {
  .list-item {
    min-height: 100px;
  }

  .config-item + .config-item {
    margin-top: 79px;
  }

  .config-item {
    padding-top: 20px;
    .config-title {
      padding-left: 8px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 16px;
      border-left: 3px #3479ef solid;
    }
    .config-content {
      margin-top: 32px;
      padding-left: 11px;
      .config-position {
        display: flex;
      }
      .mTop {
        margin-top: 20px;
      }
      .mLeft {
        margin-left: 20px;
      }
      .info {
        font-size: 12px;
        color: #8c8e91;
        line-height: 12px;
      }
    }
  }
}
</style>
