import api from "@/base/utils/request";

// 获取配置
export const companyTopConfig = data => {
  return api({
    url: "/admin/cyc/settings/companyTopConfig",
    method: "post",
    data
  });
};

// 保存配置
export const setCompanyTopConfig = data => {
  return api({
    url: "/admin/cyc/settings/setCompanyTopConfig",
    method: "post",
    data
  });
};